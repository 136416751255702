<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{$t('api_configurations.api_configurations')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
<!--                    <router-link to="/settings/api-configurations" class="btn btn-light font-weight-bolder">-->
<!--                        <v-icon>mdi-chevron-left</v-icon>-->
<!--                        {{$t('back')}}-->
<!--                    </router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                <b-card no-body class="mb-1">

                    <b-card-body>
                        <div class="form-group row">

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('api_configurations.service_type')}}</label>
                                <div class="input-group">
                                    <select name="" id="service_type" v-model="data.service_type" class="custom-select">
                                        <option v-for="row in service_types_list" :value="row.id" :key="row.id">
                                            {{ row.name }}
                                        </option>
                                    </select>
                                    <span v-if="validation && validation.service_type" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.service_type[0] }}
                                    </span>
                                </div>
                            </div>


                            <div class="col-lg-6 mb-5">
                                <label>{{$t('api_configurations.base_url')}}<span class="text-danger">*</span></label>
                                <input type="text" v-model="data.base_url" class="form-control" :class="validation && validation.base_url ? 'is-invalid' : ''" :placeholder="$t('api_configurations.base_url')"/>
                                <span v-if="validation && validation.base_url" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.base_url[0] }}
                                    </span>
                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('api_configurations.service_provider')}}<span class="text-danger">*</span></label>
                                <input type="text" v-model="data.service_provider" class="form-control" :class="validation && validation.service_provider ? 'is-invalid' : ''" :placeholder="$t('api_configurations.service_provider')"/>
                                <span v-if="validation && validation.service_provider" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.service_provider[0] }}
                                    </span>
                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('api_configurations.slug')}}<span class="text-danger">*</span></label>
                                <input type="text" v-model="data.slug" class="form-control" :class="validation && validation.slug ? 'is-invalid' : ''" :placeholder="$t('api_configurations.slug')"/>
                                <span v-if="validation && validation.slug" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.slug[0] }}
                                    </span>
                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>&ensp;</label>
                                <b-form-checkbox size="lg" v-model="data.is_default" name="check-button" switch>{{$t('is_default')}}</b-form-checkbox>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>&ensp;</label>
                                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
                            </div>


                            <div class="col-lg-12 mb-5 mt-5">
                                <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                                    <h6 class="mt-2">{{$t('api_configurations.credentials')}}</h6>
                                    <button type="button" class="btn btn-primary" @click="addRepeater"><i class="fas fa-plus"></i>{{$t('add_more')}}</button>
                                </div>
                                <table class="table" @keyup.alt.enter="addRepeater"  @keyup.alt.46="removeRepeater(0)">
                                    <thead>
                                    <tr>
                                        <th scope="col">{{$t('api_configurations.attribute')}}</th>
                                        <th scope="col">{{$t('api_configurations.value')}}</th>
                                        <th scope="col">{{ $t('actions') }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in data.credentials" :key="index">
                                        <td>
                                            <input type="text" v-model="item.attribute" class="form-control"  :placeholder="$t('api_configurations.attribute')"/>
                                        </td>
                                        <td>
                                            <input type="text" v-model="item.value" class="form-control"  :placeholder="$t('api_configurations.value')"/>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-link mr-2" @click="removeRepeater(index)"><i class="fas fa-trash text-danger"></i></button>
                                        </td>
                                    </tr>


                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </b-card-body>
                </b-card>


            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                    </div>
                </div>
            </div>

        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {

        data() {
            return {
                mainRoute: 'settings/api-configurations',
                mainRouteDependency: 'base/dependency',

                service_types_list: [
                    {id: 1, name: this.$t('service_types_list.payment')},
                    {id: 2, name: this.$t('service_types_list.shipment')},
                    {id: 3, name: this.$t('service_types_list.call')},
                    {id: 4, name: this.$t('service_types_list.sms')},
                    {id: 5, name: this.$t('service_types_list.email')},
                    {id: 6, name: this.$t('service_types_list.advertisement')},
                ],

                idEdit: this.$route.params.id ? this.$route.params.id : '',
                data: {
                    base_url: null,
                    service_provider: null,
                    service_type: null,
                    is_default: false,
                    is_active: true,
                    slug: null,
                    credentials: [{attribute: null, value:null}],
                },
                repeater: {attribute: null, value:null},
                isEditing: false,
                validation: null,
            };
        },

        methods: {
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                // this.data.is_active = this.data.is_active ? '1' : '0';
                // this.data.is_default = this.data.is_default ? '1' : '0';

                ApiService.post(this.mainRoute, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/settings/api-configurations');
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            update() {
                // this.data.is_active = this.data.is_active ? '1' : '0';
                // this.data.is_default = this.data.is_default ? '1' : '0';
                ApiService.put(this.mainRoute + '/' + this.idEdit, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/settings/api-configurations');
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            getData() {
                this.reloadUploadAttachment = false;
                ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
                    this.isEditing = true;
                    this.data = response.data.data;
                    if (response.data.data.credentials && response.data.data.credentials.length <= 0){
                        this.addRepeater();
                    }
                });
            },
            addRepeater(){
                this.data.credentials.unshift(this.repeater);
                this.repeater = {attribute: null, value:null};
            },
            removeRepeater(index){
                if (this.data.credentials.length > 1)
                    this.data.credentials.splice(index,1);
                }
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Api_configurations"), route:'/settings/api-configurations'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

            if (this.idEdit) {
                this.getData();
            }
        },
    };
</script>


